import { Box, FormControlLabel, Switch } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslate } from "react-admin";

const BooleanInput = ({ value, handleChangeField, source }) => {
  const translate = useTranslate();
  const [state, setState] = useState(value === undefined ? false : !!value);

  useEffect(() => {
    const timeOutId = setTimeout(
      () => state !== value && handleChangeField(state, source),
      1000
    );
    return () => clearTimeout(timeOutId);
  }, [state]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box display="flex" justifyContent="space-between">
      <FormControlLabel
        control={
          <Switch
            checked={state}
            onChange={(e) => setState(e.target.checked)}
            color="primary"
          />
        }
        label={translate("resources.pages.fields." + source)}
        labelPlacement="start"
      />
    </Box>
  );
};

export default BooleanInput;
