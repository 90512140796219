import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { colors } from "Static/blocks/ColorOption";
import { useTranslate } from "ra-core";
import React, { useEffect, useState } from "react";
import BooleanInput from "../../views/pages/BooleanInput";
import { ImageSelect } from "../fields/ImageSelect";
import ListDnd from "./ListDnd";
import ListImgs from "./ListImgs";
import SelectCategories from "./SelectCategories";

const useStyles = makeStyles((theme) =>
  createStyles({
    chip: {
      marginLeft: "10px",
      width: "20px",
      height: "20px",
      borderRadius: "50%",
    },
    heading: {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: theme.typography.fontWeightBold,
    },
    selectCategory: {
      width: "auto",
      minWidth: "250px",
      marginRight: "30px",
      marginBottom: "24px",
    },
    textField: {
      width: "auto",
      minWidth: "250px",
      marginRight: "30px",
      marginBottom: "24px",
    },
    selectNotes: {
      flex: 1,
    },
    imgContainer: {
      marginTop: "15px",
      marginBottom: "25px",
    },
    tooltip: {
      margin: 0,
      fontSize: 12,
    },
  })
);

const ColorField = ({ value, name }) => {
  const classes = useStyles();
  return (
    <Chip
      variant="outlined"
      label={name || "Default"}
      icon={
        <span
          className={classes.chip}
          style={{
            background: value || "white",
          }}
        ></span>
      }
    />
  );
};

const ImagenNotasForm = ({
  handleCallback,
  zoneIndex,
  zone,
  componentData,
  listCategories,
}) => {
  const translate = useTranslate();
  const classes = useStyles();
  const [listNotes, setListNotes] = useState();
  const [list, setListImgs] = useState(componentData?.list);
  const [title, setTitle] = useState(componentData?.title ?? "");
  const [title_image_link, setTitleImageLink] = useState(
    componentData?.title_image_link ?? ""
  );

  const FONTCOLOR = [
    { id: "black", name: `${translate("components.black")}` },
    { id: "white", name: `${translate("components.white")}` },
  ];
  const handleChangeListNote = (list) => {
    if (list && list.length > 0) {
      componentData.notes = [];
      list.forEach((elem) => {
        componentData.notes.push(elem.id ? elem : "");
      });
      handleCallback(componentData);
    }
  };

  const handleChangeListImgs = (newstate) => {
    if (list && list.length > 0) {
      setListImgs(newstate);
      componentData.list = newstate;
      handleCallback(componentData);
    }
  };

  useEffect(() => {
    const timeOutId = setTimeout(
      () => title !== componentData.title && handleChangeField(title, "title"),
      800
    );
    return () => clearTimeout(timeOutId);
  }, [title]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        title_image_link !== componentData.title_image_link &&
        handleChangeField(title_image_link, "title_image_link"),
      800
    );
    return () => clearTimeout(timeOutId);
  }, [title_image_link]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const list = [];
    if (componentData.list) {
      componentData.list.forEach((elem) => {
        list.push(elem);
      });
    }
    setListImgs(list);
  }, [componentData.list]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangeField = (value, field) => {
    componentData[field] = value;
    handleCallback(componentData);
  };

  const handleChangeBoolean = (value, field) => {
    componentData[field] = value;
    handleCallback(componentData);
  };

  useEffect(() => {
    const list = [];
    if (componentData.notes) {
      componentData.notes.forEach((elem) => {
        list.push(elem ? elem : {});
      });
    }
    setListNotes(list);
  }, [componentData.notes]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    componentData && (
      <Box>
        <Box display="flex" justifyContent="space-between" gridGap={12}>
          <Box display="flex" flexDirection="column">
            <TextField
              label={translate("components.title_section")}
              margin="dense"
              variant="filled"
              className={classes.textField}
              value={title || ""}
              onChange={(e) => setTitle(e.target.value)}
            />

            <Box
              mb="1em"
              display="flex"
              flexDirection="column"
              style={{
                border: "1px solid #e6e6e6",
                display: "flex",
                padding: "12px",
                borderRadius: "4px",
                backgroundColor: "#f5f5f5",
              }}
            >
              <Typography variant="caption" style={{ fontWeight: 600 }}>
                {translate("resources.pages.fields.sponsor")}
              </Typography>
              <Box mr="1em" display="flex" flexDirection="column">
                <ImageSelect
                  imgSize={{ imgWidth: "150px", imgHeight: "50px" }}
                  handleSelected={(value) =>
                    handleChangeField(value, "title_image")
                  }
                  componentData={componentData}
                  hiddeLabel={true}
                  field="title_image"
                />
                <Typography variant="caption">
                  {translate("resources.pages.fields.helper_title_image")}
                </Typography>
              </Box>
              <TextField
                margin="dense"
                variant="filled"
                className={classes.textField}
                value={title_image_link || ""}
                onChange={(e) => setTitleImageLink(e.target.value)}
                label={translate("resources.pages.fields.link_sponsor")}
              />
            </Box>

            {listCategories.length > 0 ? (
              <SelectCategories
                listCategories={listCategories}
                value={componentData.category || ""}
                handleChangeField={handleChangeField}
              />
            ) : (
              <div className={classes.selectInput}>
                <LinearProgress />
              </div>
            )}

            <FormControl variant="filled">
              <InputLabel className={classes.inputLabel}>
                {translate("components.background")}
              </InputLabel>
              <Select
                onChange={(e) => {
                  handleChangeField(e.target.value, "background");
                }}
                fullWidth
                className={classes.selectCategory}
                value={componentData.background || ""}
              >
                <MenuItem value="">Default</MenuItem>
                {colors.map((color, index) => (
                  <MenuItem key={index} value={color.name}>
                    <ColorField value={color.value} name={color.title} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl variant="filled">
              <InputLabel className={classes.inputLabel}>
                {translate("components.font_color")}
              </InputLabel>
              <Select
                onChange={(e) => {
                  handleChangeField(e.target.value, "fontcolor");
                }}
                fullWidth
                className={classes.selectCategory}
                value={componentData.fontcolor || ""}
              >
                <MenuItem value="">Default</MenuItem>
                {FONTCOLOR.map((color, index) => (
                  <MenuItem key={index} value={color.id}>
                    {translate(`components.${color.id}`)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            className={classes.selectNotes}
          >
            <Typography className={classes.heading}>
              {translate("components.image")}
            </Typography>
            <Box className={classes.imgContainer}>
              {list && (
                <ListImgs
                  list={list}
                  handleChangeListImgs={handleChangeListImgs}
                  componentData={componentData}
                />
              )}
              <p className={classes.tooltip}>
                {translate("components.imagen_note_form.help_image")}
              </p>
            </Box>
            <div>
              <Typography className={classes.heading}>
                {translate("components.notes")}
              </Typography>
              {listNotes && (
                <ListDnd
                  listNotes={listNotes}
                  handleChangeListNote={handleChangeListNote}
                />
              )}
            </div>
          </Box>
        </Box>
        <BooleanInput
          value={componentData.show_tags}
          handleChangeField={handleChangeBoolean}
          source={"show_tags"}
        />
        {componentData.show_author !== undefined && (
          <BooleanInput
            value={componentData?.show_author}
            hndleChangeField={handleChangeBoolean}
            source={"show_author"}
          />
        )}
      </Box>
    )
  );
};

export default ImagenNotasForm;
